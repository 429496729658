/*
 * Copyright 2024 ByOmakase, LLC (https://byomakase.org)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// @ts-ignore
import {Parser} from 'm3u8-parser';
import {Manifest} from './m3u8.model';

export class OmpM3u8Parser {
  constructor() {}

  static parse(manifest: string): Manifest {
    const parser = new Parser();
    parser.push(manifest);
    parser.end();
    return parser.manifest;
  }
}
